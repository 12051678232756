import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function ReuseCode(props) {
    if (props.step !== authSteps.STATE_CODE_REUSED) {
        return null;
    }

    return (
        <div className={"mb-2 flex  flex-col " + (props.isRegister || props.isChangePhone? "" : " items-center justify-center")}
             onClick={(e) => props.onSendGetCode(e, true)}>
            <a className="glowing-text justify-center items-center p-3 rounded-lg w-full max-w-xs text-white
        text-center font-normal bg-zinc-700 text-lg dark:bg-zinc-900 mb-4"
            >Получить новый код</a>
        </div>
    );
}

export default ReuseCode;