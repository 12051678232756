import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function ProviderType(props) {

    function onClickSms(e) {
        e.preventDefault()

        props.setType('SMS')

    }
    function onClickTelegram(e) {
        e.preventDefault()

        props.setType('TELEGRAM')

    }
    if (props.step === authSteps.STATE_SUCCESS ) {
        return null;

    }

    return (
        <div className="max-w-xs">
            <p className="text-left font-medium mb-4 text-textLight dark:text-textDarkLightGray text-lg">
                Выберите как хотите получить код:</p>
            <div className="soa-property-container flex justify-between max-w-xs w-full"
                 id="bx_934924412_radio_buttons">
                <div className="w-full pr-1">
                    <button onClick={onClickSms} className={"btn link_menu_catalog get_code_button p-3 rounded-lg " +
                                    "cursor-pointer font-normal  text-lg  mb-2 items-center w-full " +
                        (props.type === 'SMS' ? "text-black bg-white border-2 dark:text-light-red dark:border-white border-light-red ": "text-white bg-light-red ")}>
                        SMS
                    </button>
                </div>

                <div className={"w-full " } >
                    <button onClick={onClickTelegram} className={"btn link_menu_catalog get_code_button p-3 rounded-lg "
                                   + "cursor-pointer font-normal  text-lg mb-2 items-center w-full " +
                        (props.type === 'TELEGRAM' ? "text-black bg-white dark:text-light-red dark:border-white border-2 " +
                            "border-light-red ": "text-white bg-light-red ") + (props.tgEnabled === 'Y' ? 'block' : 'hidden')}>
                        Telegram
                    </button>

                </div>
            </div>
        </div>

    );
}

export default ProviderType;