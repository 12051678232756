export const authSteps = {
    STATE_INIT: 'INIT',
    STATE_EXPIRED: 'EXPIRED',
    STATE_PHONE_WAITING: '1',
    STATE_USER_WAITING: '2',
    STATE_CODE_WAITING: '3',
    STATE_SUCCESS: '4',
    STATE_TG_USER_ABSENT: '9',


    STATE_CODE_NOT_VALID: '5',
    STATE_CODE_REUSED: '6',
    STATE_PHONE_EXISTS: '7',
    STATE_CAPTCHA_WRONG: '8',

    ERROR_CODE_NOT_CORRECT: 'CODE_NOT_CORRECT',
    ERROR_TIME_EXPIRED: 'TIME_EXPIRED',
    ERROR_PHONE_EXISTS: 'PHONE_EXISTS',
    ERROR_CAPTCHA_WRONG: 'CAPTCHA_WRONG',
    STATE_ERROR: 'STATE_ERROR',

}
