import Autoplay from 'embla-carousel-autoplay';

document.addEventListener("DOMContentLoaded", () => {

    const TWEEN_FACTOR_BASE = 0.2
    let tweenFactor = 0
    let tweenNodes = []
    const OPTIONS = {loop: true}
    const emblaNode = document.querySelector('.embla-1-slides')

    if (emblaNode) {
        const header = document.querySelector('header #child').offsetHeight

        if (window.innerWidth > 1024) {
            emblaNode.closest('.slider-box').style.top = '-' + (header + 30) + 'px'
        } else {
            emblaNode.closest('.slider-box').style.top = ''
        }

        const autoplayOptions = {
            delay: 4000, // Задержка между слайдами в миллисекундах
            stopOnInteraction: false, // Остановить при взаимодействии с пользователем
            stopOnMouseEnter: true, // Остановить при наведении курсора
        };

        const viewportNode = emblaNode.querySelector('.embla__viewport')
        const dotsNode = emblaNode.querySelector('.embla__dots')
        const emblaApi = EmblaCarousel(viewportNode, OPTIONS,[Autoplay(autoplayOptions)])
        viewportNode.classList.remove('hidden');
        const setTweenNodes = (emblaApi) => {
            tweenNodes = emblaApi.slideNodes().map((slideNode) => {
                return slideNode.querySelector('.embla__parallax__layer')
            })
        }

        const setTweenFactor = (emblaApi) => {
            tweenFactor = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length
        }

        const tweenParallax = (emblaApi, eventName) => {
            const engine = emblaApi.internalEngine()
            const scrollProgress = emblaApi.scrollProgress()
            const slidesInView = emblaApi.slidesInView()
            const isScrollEvent = eventName === 'scroll'

            emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
                let diffToTarget = scrollSnap - scrollProgress
                const slidesInSnap = engine.slideRegistry[snapIndex]

                slidesInSnap.forEach((slideIndex) => {
                    if (isScrollEvent && !slidesInView.includes(slideIndex)) return

                    if (engine.options.loop) {
                        engine.slideLooper.loopPoints.forEach((loopItem) => {
                            const target = loopItem.target()

                            if (slideIndex === loopItem.index && target !== 0) {
                                const sign = Math.sign(target)

                                if (sign === -1) {
                                    diffToTarget = scrollSnap - (1 + scrollProgress)
                                }
                                if (sign === 1) {
                                    diffToTarget = scrollSnap + (1 - scrollProgress)
                                }
                            }
                        })
                    }

                    const translate = diffToTarget * (-1 * tweenFactor) * 100
                    const tweenNode = tweenNodes[slideIndex]
                    tweenNode.style.transform = `translateX(${translate}%)`
                })
            })
        }

        const setupTweenParallax = (emblaApi) => {
            setTweenNodes(emblaApi)
            setTweenFactor(emblaApi)
            tweenParallax(emblaApi)

            emblaApi
                .on('reInit', setTweenNodes)
                .on('reInit', setTweenFactor)
                .on('reInit', tweenParallax)
                .on('scroll', tweenParallax)
                .on('slideFocus', tweenParallax)

            return () => {
                tweenNodes.forEach((slide) => slide.removeAttribute('style'))
            }
        }

        const addDotBtnsAndClickHandlers = (emblaApi, dotsNode) => {
            let dotNodes = []

            const addDotBtnsWithClickHandlers = () => {
                if (dotsNode !== undefined) {
                    dotsNode.innerHTML = emblaApi.scrollSnapList().map(() => '<div class="embla__dot"></div>').join('')
                    const scrollTo = (index) => {
                        emblaApi.scrollTo(index)
                    }

                    dotNodes = Array.from(dotsNode?.querySelectorAll('.embla__dot'))
                    dotNodes.forEach((dotNode, index) => {
                        dotNode.addEventListener('click', () => scrollTo(index), false)
                    })
                }
            }

            const toggleDotBtnsActive = () => {
                const previous = emblaApi.previousScrollSnap()
                const selected = emblaApi.selectedScrollSnap()
                dotNodes[previous]?.classList.remove('embla__dot--selected')
                dotNodes[selected]?.classList.add('embla__dot--selected')
            }

            emblaApi
                .on('init', addDotBtnsWithClickHandlers)
                .on('init', toggleDotBtnsActive)
                .on('select', toggleDotBtnsActive)

            return () => {
                dotsNode.innerHTML = ''
            }
        }

        const removeTweenParallax = setupTweenParallax(emblaApi)

        const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
            emblaApi,
            dotsNode
        )

        emblaApi
            .on('destroy', removeTweenParallax)
            .on('destroy', removeDotBtnsAndClickHandlers)

        if (window.innerWidth > 1024) {
            const headerItems = document.querySelector('header');

            const changeHeaderColor = () => {
                const selectedIndex = emblaApi.selectedScrollSnap(); // Текущий индекс слайда
                const slides = emblaApi.slideNodes(); // Все слайды
                const currentSlide = slides[selectedIndex]; // Активный слайд
                const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

                // Меняем цвет шапки
                headerItems.classList.remove('changeWhiteSlide')
                headerItems.classList.remove('changeDarkSlide')
                if (scrollTop === 0) {
                    if (currentSlide.dataset.color === 'светлый') {
                        headerItems.classList.add('changeWhiteSlide')
                        headerItems.dataset.tag = 'changeWhiteSlide'
                    } else {
                        headerItems.dataset.tag = 'changeDarkSlide'
                        headerItems.classList.add('changeDarkSlide')
                    }
                }
            };

            emblaApi.on('select', changeHeaderColor);
            changeHeaderColor();// При смене слайда
            $(window).scroll(function () {
                const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                if (scrollTop <= 0) {
                    changeHeaderColor();
                }
            })
        }
    }

});