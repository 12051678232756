import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";
import CodeForm from "./CodeForm";
import EmailForm from "./EmailForm";
import PropTypes from "prop-types";

AuthorizationForm.propTypes = {
    onValidate: PropTypes.func,
};

function AuthorizationForm(props) {

    const [phone, setPhone] = useState('')
    const [phoneAuth, setPhoneAuth] = useState(true)
    const [emailAuth, setEmailAuth] = useState(false)
    const [type, setType] = useState('SMS')
    const [errors, setErrors] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [reuseTime, setReuseTime] = useState(0)

    const [tgName, setTgName] = useState('')
    const [tgUrl, setTgUrl] = useState('')
    const [tgEnabled, setTgEnabled] = useState('Y')
    const [step, setStep] = useState(authSteps.STATE_PHONE_WAITING)

    const rootForm = useRef();

    const onEndTimer = () => {
        setStep(authSteps.STATE_CODE_REUSED);
        setReuseTime(0)
    }

    useEffect(() => {

        if (step === authSteps.STATE_PHONE_WAITING) {
            axios.post('/local/components/ctweb/sms.authorize/ajax.php', {
                    'initialize' : 'Y',
                    'is_ajax_post': 'Y',
                    sessid: BX.message("bitrix_sessid"),

                }, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    }
                }
            ).then(response => {
                if (response.status===200) {
                    setStep(response.data.STEP)

                    if (response.data.USER_VALUES.PROVIDER_TYPE !== null ) {
                        if (response.data.USER_VALUES.PROVIDER_TYPE === 'SMS') {
                            setType('SMS')
                        } else {
                            setType('TELEGRAM')
                        }

                    }

                    setReuseTime(response.data.REUSE_TIME ?? 0)
                    setPhone(response.data?.USER_VALUES.PHONE ?? '')
                    if (response.data.TG_CONFIG) {
                        setTgName(response.data.TG_CONFIG.NAME)
                        setTgUrl(response.data.TG_CONFIG.URL)
                        setTgEnabled(response.data.TG_CONFIG.ENABLED)
                    }
                } else {
                    alert('Error get init params')
                }
            })
        }

    }, []);

    return (
        <>
            <div ref={rootForm} className={"h-full flex md:block " + (props.isRegister || props.isChangePhone?
                "" : "items-center justify-center")}>
                <div>
                    <div className="row">
                        <CodeForm setStep={setStep} step={step} tgName={tgName} phone={phone} onEndTimer={onEndTimer}
                                  tgUrl={tgUrl} tgEnabled={tgEnabled} setType={setType} type={type}
                                  errors={errors} setErrors={setErrors} setPhoneAuth={setPhoneAuth}
                                  setEmailAuth={setEmailAuth} phoneAuth={phoneAuth} rootForm={rootForm}
                                  setLoaded={setLoaded} loaded={loaded} reuseTime={reuseTime} setReuseTime={setReuseTime}
                                  isRegister={props.isRegister} isChangePhone={props.isChangePhone} onValidate={props.onValidate}
                        />
                        <EmailForm setPhoneAuth={setPhoneAuth} setEmailAuth={setEmailAuth} emailAuth={emailAuth}
                                   setStep={setStep} step={step} rootForm={rootForm} loaded={loaded}
                                   setLoaded={setLoaded}/>
                    </div>
                </div>
            </div>
    </>

);
}

export default AuthorizationForm;