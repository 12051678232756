import React, {useEffect, useState} from 'react';
import {authSteps} from "./authSteps";
import PropTypes from "prop-types";

const TIME_REUSE = 60;

TimeEscaped.propTypes = {
    reuseTime: PropTypes.number,
};

function TimeEscaped(props) {

    const [delta, setDelta] = useState(0)
    const [finishTime, setFinishTime] = useState(0)
    const [timerId, setTimerId] = useState(null)

    useEffect(() => {

        if (props.step === authSteps.STATE_CODE_WAITING) {
            if (finishTime !== 0) {
                setTimerId(setInterval(function () {
                        const now = Math.floor(+new Date() / 1000);
                        setDelta(finishTime-now)
                    }, 1000
                ));

            }
        } else {
            clearInterval(timerId);
        }
    }, [finishTime]);

    useEffect(() => {
        if (timerId !== null && delta <= 0) {
            props.onEndTimer();
            clearInterval(timerId);
            setTimerId(null);
        }
    }, [delta]);

    useEffect(() => {
        if (props.step === authSteps.STATE_CODE_WAITING) {
           setFinishTime(props.reuseTime === 0
               ? Math.floor(+new Date() / 1000) + TIME_REUSE
               : props.reuseTime)
            setDelta(0)
        } else {
            clearInterval(timerId)
            setTimerId(null);
            setFinishTime(0)
            setDelta(0)
        }
    }, [props?.step]);

    return (
        delta > 0
            ? <div className={"text-sm dark:font-normal font-light"}>
                Повторный код через <span className='glowing-text'>00:{String(delta).padStart(2, "0")}</span>
            </div>
            : <></>
    );
}

export default TimeEscaped;