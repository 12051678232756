import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function Email(props) {

    return (
        <div className={"mb-2 " + (props.isRegister || props.isChangePhone ? "hidden" : "")} >
            <a className="flex flex-row items-center text-sm
                                               dark:font-normal font-medium hover:underline"
               onClick={props.setFormVisible}>
                <span className="mr-2.5 p-2 dark:bg-grayButton border
                                                 border-textDarkLightGray dark:border-grayButton rounded-full">
                    <svg width="17" height="17" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         className="dark:fill-white fill-light-red">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M24 5.35328V20.2225L18.198 12.9707L17.0955 13.853L22.954 21.1768H1.04605L6.90454 13.853L5.80201 12.9707L0 20.2225V5.35326L11.9993 15.7588L24 5.35328ZM23.9992 2.82495V3.48279L11.9999 13.8883L0.0005625 3.4828V2.82495H23.9992Z"
                        />
                    </svg>
                </span>
               Войти через почту</a>
        </div>
    );
}

export default Email;