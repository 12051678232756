function ecommerceYandex() {
    document.addEventListener('itemAdded', function (event) {
        if (event.detail.type === 'add') {
            window.dataLayer.push({
                "ecommerce": {
                    "currencyCode": "RUB",
                    "add": {
                        "products": [
                            {
                                "id": event.detail.id,
                                "name": event.detail.name,
                                "price": event.detail.price,
                                "category": event.detail.brand,
                                "quantity": event.detail.quantity
                            }
                        ]
                    }
                }
            });
        } else {
            window.dataLayer.push({
                "ecommerce": {
                    "currencyCode": "RUB",
                    "remove": {
                        "products": [
                            {
                                "id": event.detail.id,
                                "name": event.detail.name,
                                "price": event.detail.price,
                                "category": event.detail.brand,
                                "quantity": event.detail.quantity
                            }
                        ]
                    }
                }
            });
        }
    });
    document.addEventListener('itemDetail', function (event) {
        window.dataLayer.push({
            "ecommerce": {
                "currencyCode": "RUB",
                "detail": {
                    "products": [
                        {
                            "id": event.detail.id,
                            "name": event.detail.name,
                            "price": event.detail.price,
                            "brand": event.detail.brand,
                            "category": event.detail.category,
                        }
                    ]
                }
            }
        });
    })

    document.addEventListener('orderAdd', function (event) {
        let order = [];
        if (event.detail.products) {
            Object.entries(event.detail.products).forEach(([key, item]) => {
                let elem = item.data;
                order.push({
                    "id": elem.PRODUCT_ID,
                    "name": elem.NAME,
                    "price": elem.PRICE,
                    "category": elem.CATEGORY,
                    "quantity": elem.QUANTITY,
                });
                return order;
            });

            window.dataLayer.push({
                "ecommerce": {
                    "currencyCode": "RUB",
                    "purchase": {
                        "actionField": {
                            "id": event.detail.order
                        },
                        "products": order
                    }
                }
            });
        }
    })
}

export default ecommerceYandex;