import React, {useEffect, useState} from "react";
import {authSteps} from "./authSteps";

function EmailLogin(props) {

    const [visible, setVisible] = useState(true)

    useEffect(() => {
        //TODO заменить на библиотеку из npm
        const node = document.querySelector("input[name='EMAIL']")
        $(node).inputmask("email", {
            removeMaskOnSubmit: true,
            clearMaskOnLostFocus: true,
            clearIncomplete: true
        });
        node.addEventListener('keydown', (event) => {
            props.setEmail(event.target.value);
        })

    }, [visible])

    return (
        <input type="text" name="EMAIL" placeholder=""
               className="form-control bg-textDark p-3 dark:bg-grayButton cursor-pointer
                      w-full text-textLight rounded-md dark:text-white border-0 text-xl auth-by-email mb-4"
        />
    )
        ;
}

export default EmailLogin;