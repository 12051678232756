import React, {useEffect, useState} from 'react';
import {authSteps} from "./authSteps";
import PropTypes from "prop-types";

PhoneField.propTypes = {
    rootForm: PropTypes.object,
    phone: PropTypes.string,
};

function PhoneField(props) {

    useEffect(()=>{
        //TODO заменить на библиотеку из npm
        if (props.rootForm.current === undefined) {
            return
        }


        const node = props.rootForm.current.querySelector('input[name="PHONE"]')
        $(node).phonecode({
            preferCo: 'ru',
            default_prefix: '7',
            phone_val: props.phone,
        });

        $(node).inputmask("+7 (999)-999-9999", {
            minLength: 8,
            removeMaskOnSubmit: true,
            autoUnmask: true,
            clearMaskOnLostFocus: true,
            clearMaskOnLostHover: true,
            clearIncomplete: true,
            definitionSymbol: "*"
        })
    }, [props.visible])

    useEffect(() => {
        //TODO set country code
        const node = props.rootForm.current.querySelector('input[name="PHONE"]')
        $(node).val(props?.phone ?? '')
    }, [props?.phone]);

    return (
        <div className="code relative mt-2">
            <span className="" id="flag"></span>
            <input type="text" name="PHONE" placeholder="+_ (___)-___-____"
                   inputMode="text"
                   minLength="8"
                   required
                   data-input-type="phone"
                   className="bg-textDark p-3 dark:bg-grayButton cursor-pointer w-full text-textLight rounded-md
                                           dark:text-white border-0 text-xl auth-phone"
                   autoComplete="off"/>
        </div>

    );
}

export default PhoneField;