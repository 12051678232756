import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function CodeNotResponse(props) {


    const [visible, setVisible] = useState(true)
    const onClick = () => {
        $("#callbackModal").arcticmodal(
            {
                closeOnOverlayClick: true,
                afterClose: function (data, el) {
                }
            });
    }

    if (props.step !== authSteps.STATE_CODE_WAITING) {
        return null
    }
    return (
        <div className="mb-2">
            <a className="ctweb-link hover:underline flex flex-row items-center
                                              text-sm dark:font-normal font-medium"
               id="bx_934924412_msg_not_come" onClick={onClick}>
                <span className="mr-2.5 p-2 dark:bg-grayButton border
                                                 border-textDarkLightGray dark:border-grayButton rounded-full">
                    <svg width="17" height="17" viewBox="0 0 10 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         className="dark:stroke-white stroke-light-red">
                        <path
                            d="M2.125 5.08925C2.125 1.15171 8.3125 1.15175 8.3125 5.08925C8.3125 7.90175 5.5 7.33913 5.5 10.7141"
                            stroke-width="2.5" stroke-linecap="round"
                            stroke-linejoin="round"/>
                        <path d="M5.5 14.2662L5.51458 14.25"
                              stroke-width="2.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                </span>
               Не пришло сообщение?</a>
        </div>
    );
}

export default CodeNotResponse;