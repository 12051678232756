
export function lazyLoad() {
    const lazyImages = document.querySelectorAll('.js--lazy-load');

    let lazyImageObserver
    if ('IntersectionObserver' in window) {
        lazyImageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.srcset = lazyImage.dataset.srcset;
                    lazyImage.classList.remove('js--lazy-load');
                    lazyImageObserver.unobserve(lazyImage);
                }
            })
        })

        lazyImages.forEach(function (lazyImage) {
            lazyImageObserver.observe(lazyImage);
        })
    } else {
        lazyImages.forEach(function (lazyImage) {
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.srcset = lazyImage.dataset.srcset;
        })
    }

    document.addEventListener('contentUpdated', function (event) {
        const lazyImages = document.querySelectorAll('.js--lazy-load');
        if (lazyImageObserver) {
            lazyImages.forEach(function (lazyImage) {
                lazyImageObserver.unobserve(lazyImage);
            })
        }
        lazyImages.forEach(function (lazyImage) {
            lazyImageObserver.observe(lazyImage);
        })
    });
}
