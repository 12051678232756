import React, {useEffect, useState} from 'react';

function PhoneCheckPopup(props) {
    return (
        <div className="flex items-center justify-center fixed z-40 h-screen w-screen dark:bg-fancyboxDark top-0 left-0
        bg-fancybox overlay-box">
            <div className={"md:rounded-xl dark:bg-darkBox bg-white rounded-0 relative z-50 p-8 m-auto md:max-w-xl " +
                "md:w-full w-screen md:h-auto h-screen  "}>

                <div className="age-access-inner">
                    <div className="age-access__text">
                        <div className="text-dark font-medium dark:font-extralight text-lg dark:text-textDarkLightGray
                             mb-4 text-center">
                            Пожалуйста, добавьте номер телефона в личный кабинет для дальнейшего использования сайта.
                        </div>
                    </div>
                    <div className="age-access__buttons flex flex-row justify-center">
                        <a href="/personal/private/"
                           className={"btn link_menu_catalog get_code_button p-3 rounded-lg " +
                               "cursor-pointer font-normal  text-lg  mb-2 items-center " +
                               "  text-white bg-light-red"}>
                            Перейти в личный кабинет
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

            export default PhoneCheckPopup;