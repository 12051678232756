import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function TgUserAbsent(props) {
    const [visible, setVisible] = useState(false)

    if (props.step !== authSteps.STATE_TG_USER_ABSENT) {
        return null;
    }

        return (
        <div id="ctweb_form_step_9"
             className={"ctweb-smsauth-menu-step flex-col justify-center items-center mb-3 "}>
            <div className="max-w-xs flex flex-col items-center justify-center w-full">
                <div className="form-group flex flex-col">

                    <label className="ctweb-label font-light mb-4 text-textLight
                                        dark:text-textDarkLightGray text-base" htmlFor="sms-auth-code">
                        Для авторизации через Telegram, перейдите в бот
                         <a className="text-sky-500" target="_blank"
                           href={props.tgUrl}>
                             @{props.tgName}{/*<?=Enterego\TelegramBot\Helper::getTelegramBotName()?>*/}
                        </a>

                    </label>
                    <div className="flex flex-row justify-right">
                        <a className="ctweb-link font-medium mb-2 text-textLight
                                        dark:text-white underline text-xs"
                           id="bx_934924412_back" onClick={props.onChangePhone}>
                            {/*<?= GetMessage("SMS_AUTH_BACK") ?>*/}Другой способ отправки кода</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TgUserAbsent;