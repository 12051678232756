const arItemsForDB = [];
let intervalId = undefined;

export function addItemArrayAndSend(arItem) {
    let currentKey = false;

    $(arItemsForDB).each(function (key, itemVal) {
        if (itemVal.ID === arItem.ID) {
            itemVal.TIME = 2;
            itemVal.QUANTITY = arItem.QUANTITY;
            itemVal.PRICE = arItem.PRICE;
            currentKey = true;
        }
        itemVal.TYPE = arItem.TYPE;
        itemVal.BRAND = arItem.BRAND;
    });
    if (currentKey === false) {
        arItem.TIME = 2;
        arItemsForDB.push(arItem);
    }

    if (intervalId === undefined) {
        intervalId = setTimeout(() => sendArrayItems(arItemsForDB), 3000);
    } else {
        clearTimeout(intervalId);
        intervalId = setTimeout(() => sendArrayItems(arItemsForDB), 3000);
    }

}

function sendArrayItems(ItemArray) {
    const product_data = ItemArray;
    if (product_data.length !== 0) {
        $.ajax({
            type: 'POST',
            url: '/local/templates/Oshisha/include/add2basket.php',
            data: 'product_data=' + JSON.stringify(product_data),
            success: function (result) {
                if (result.STATUS === 'success') {
                    deleteBasketItemTop(result);
                    product_data.map(function (itemVal) {
                        const itemAddedEvent = new CustomEvent('itemAdded', {
                            detail: {
                                type: itemVal.TYPE,
                                id: itemVal.ID,
                                name: result.ITEMS[itemVal.ID]?.NAME,
                                brand:  itemVal.BRAND,
                                price: result.ITEMS[itemVal.ID]?.PRICE,
                                quantity: itemVal.QUANTITY,
                            },
                        });
                        document.dispatchEvent(itemAddedEvent);
                    })
                    intervalId = undefined;
                    arItemsForDB.length = 0;
                }
            }
        });
    }
}

function deleteBasketItemTop(result) {
    if (result.QUANTITY !== '' && result.QUANTITY !== 0) {
        $('.spanBasketTop').attr('style', 'padding: 3px 6px;').text(result.QUANTITY);
        $('.price_basket_top').text(result.SUM_PRICE + ' ₽');
    } else {
        $('.spanBasketTop').remove();
        $('.price_basket_top').remove();
    }
}