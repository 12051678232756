document.addEventListener("DOMContentLoaded", function () {
    setSubsidiary()
})

export function setSubsidiary() {
    const subsidary = document.querySelectorAll('.js--subsidiary_link');
    if (subsidary) {
        subsidary.forEach(node => {
            node.addEventListener('change', (e) => {
                loaderForSite('appendLoader')
                BX.ajax({
                    url: '/local/ajax/subsidiary.php',
                    method: 'POST',
                    data: {subsidiary: event.target.value},
                    onsuccess: function (response) {
                        if (response === 'success') {
                            location.reload()
                            document.addEventListener("DOMContentLoaded", () => {
                                loaderForSite('remove')
                            });
                        }
                    }
                })
            })
        })
    }
}