import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function BackButton(props) {





    return (<div className="ctweb-button-back hidden mb-2">
        <a className="ctweb-link flex flex-row items-center text-sm
                                               dark:font-normal font-medium hover:underline"
           id="<?= $jsParams['TEMPLATE']['BACK'] ?>">
            <span className="mr-2.5 p-2 dark:bg-grayButton border
                  border-textDarkLightGray dark:border-grayButton rounded-full">
                <svg width="17" height="20" viewBox="0 0 19 22" fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     className="dark:fill-white fill-light-red">
                    <path
                        d="M9.49743 0.666504V3.63793C4.7569 3.63793 0.893433 7.64072 0.893433 12.5522C0.893433 17.4637 4.7569 21.4665 9.49743 21.4665C14.238 21.4665 18.1014 17.4637 18.1014 12.5522C18.1014 10.6009 17.485 8.79748 16.4527 7.32707L15.0897 8.73927C15.7835 9.83224 16.1894 11.1408 16.1894 12.5522C16.1894 16.3931 13.2046 19.4855 9.49743 19.4855C5.79022 19.4855 2.80543 16.3931 2.80543 12.5522C2.80543 8.71131 5.79022 5.61888 9.49743 5.61888V8.59031L14.2774 4.62841L9.49743 0.666504Z"
                    />
                </svg>
            </span>
            Назад
        </a>
    </div>);
}

export default BackButton;