import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function SmsMessage(props) {
    if (props.step !== authSteps.STATE_CODE_WAITING || props.type !== 'SMS') {
        return null;
    }

        return (
        <div
             className={"ctweb-smsauth-menu-step flex-col justify-center items-center mt-3 "}>
            <div className="max-w-xs flex flex-col items-center justify-center w-full">
                <div className="form-group flex flex-col">

                    <label className="ctweb-label font-light mb-4 text-textLight
                                        dark:text-textDarkLightGray text-base" htmlFor="sms-auth-code">
                        Код отправлен в личные сообщения
                    </label>
                </div>
            </div>
        </div>
    );
}

export default SmsMessage;