import React, {useEffect, useState} from 'react';
import {authSteps} from "./authSteps";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";
import PhoneField from "./PhoneField";

PhoneWaiting.propTypes = {
    rootForm: PropTypes.object,
    phone: PropTypes.string,
};


function PhoneWaiting(props) {
    return (
        <div className={"mb-4 justify-center " }>
            <div className={(props.isChangePhone ? "" : "max-w-xs")}>

                <p className={"md:text-2xl dark:text-textDarkLightGray text-textLight flex flex-row items-center "
                        + " mb-5 mt-8 text-xl" + (props.isChangePhone ? "" : " hidden")}>
                    <span className="mr-4 md:font-semibold font-medium">Изменение номера телефона</span>
                    <svg width="21" height="20" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="dark:fill-white fill-black"
                              d="M0 3.19444C0 14.1327 9.01098 23 20.1266 23C20.628 23 21.1252 22.982 21.6176 22.9465C22.1826 22.9057 22.465 22.8854 22.7222 22.7397C22.9352 22.6191 23.1371 22.4052 23.2439 22.1873C23.3728 21.9244 23.3728 21.6176 23.3728 21.0041V17.4042C23.3728 16.8883 23.3728 16.6303 23.2864 16.4092C23.2104 16.2139 23.0865 16.0399 22.926 15.9027C22.7443 15.7473 22.4979 15.6592 22.0052 15.4828L17.8412 13.9928C17.2679 13.7877 16.9812 13.6851 16.7093 13.7025C16.4695 13.7178 16.2388 13.7985 16.0427 13.9352C15.8204 14.0902 15.6635 14.3475 15.3497 14.8623L14.2834 16.6111C10.8425 15.0777 8.0531 12.3292 6.49244 8.94444L8.26959 7.89516C8.79265 7.58633 9.05418 7.43191 9.21174 7.21313C9.35068 7.02021 9.43261 6.79314 9.44819 6.55717C9.46585 6.28957 9.36158 6.00751 9.15318 5.4434L7.63899 1.34577C7.45982 0.860915 7.37023 0.618483 7.21235 0.439683C7.07288 0.281737 6.89613 0.159914 6.69763 0.0849085C6.47291 1.52323e-07 6.21077 0 5.6865 0H2.02825C1.40481 0 1.09308 9.52019e-08 0.825813 0.12682C0.604446 0.231866 0.387131 0.430624 0.264502 0.640205C0.116449 0.893256 0.0957634 1.17124 0.0543936 1.72721C0.0183475 2.21165 0 2.70094 0 3.19444Z"></path>
                    </svg>
                </p>

                <p className={"text-center font-medium mb-4 text-textLight dark:text-textDarkLightGray text-lg "
                    + (props.isRegister || props.isChangePhone ? "hidden" : "")}>
                    Войдите или зарегистрируйтесь
                </p>
                <div>
                    <div className="form-group relative mb-4 max-w-xs">
                        <div>
                            <label className="text-xs font-normal mb-2 text-textLight dark:text-textDarkLightGray"
                                   htmlFor="smsauth-phone">
                                <span className="starrequired text-light-red md:text-hover-red">* </span>
                                Номер телефона
                            </label>
                            <PhoneField rootForm={props.rootForm} phone={props.phone}/>
                        </div>
                    </div>

                    <Checkbox isChangePhone={props.isChangePhone}/>
                </div>
            </div>
        </div>
    );
}

export default PhoneWaiting;