import React, {useEffect, useState} from "react";
import {authSteps} from "./authSteps";

function EmailError(props) {

    if (props.errors.length === 0) {
        return null;
    }


    return (
        <div className="ctweb-error-alert text-red-600" id="bx_934924412_error_alert">
            {props.errors}
        </div>
    );
}

export default EmailError;