import React, {StrictMode, useEffect, useState} from 'react';
import axios from 'axios';
import {authSteps} from "./authSteps";

function EmailRegister(props) {


    const [visible, setVisible] = useState(true)


    return (
        <div className={"mb-2 " + (props.isRegister || props.isChangePhone? "hidden" : "")}>
            <a className="hover:underline flex flex-row items-center
                                              text-sm dark:font-normal font-medium"
               href="/auth/?register=yes">
                <span className="mr-2.5 p-2 dark:bg-grayButton border
                                                 border-textDarkLightGray dark:border-grayButton rounded-full">
                    <svg width="16" height="17" viewBox="0 0 21 22" fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         className="dark:fill-white fill-light-red">
                        <path
                            d="M10.4997 10.451C13.3856 10.451 15.7252 8.11148 15.7252 5.22551C15.7252 2.33954 13.3856 0 10.4997 0C7.61371 0 5.27417 2.33954 5.27417 5.22551C5.27417 8.11148 7.61371 10.451 10.4997 10.451Z"
                        />
                        <path
                            d="M10.5 13.0635C4.71286 13.0635 0 16.575 0 20.9017C0 21.1944 0.254125 21.4243 0.577556 21.4243H20.4224C20.7459 21.4243 21 21.1944 21 20.9017C21 16.575 16.2871 13.0635 10.5 13.0635Z"
                        />
                    </svg>
                </span>
                {/*<?= GetMessage("EMAIL_AUTH_REGISTRATION") ?>*/}Зарегистрироваться
            </a>
        </div>
    );
}

export default EmailRegister;